<template>
  <Head>
    <title>SDG Tracker | M-Changa Africa</title>
    <meta
      name="description"
      content="M-Changa Africa: Start your fundraiser in three quick steps."
    />
    <meta name="author" content="Mobi Changa" />
    <meta property="og:type" content="website" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta property="og:url" :content="this.baseURL + '/signup'" />
    <meta property="og:title" content="Sign Up | M-Changa Africa" />
    <meta
      property="og:description"
      content="M-Changa Africa: Start your fundraiser in three quick steps."
    />
    <meta property="fb:app_id" :content="this.facebookID" />
  </Head>
  <div>
    <section id="content">
      <div class="content-wrap py-0">
        <div class="section nobg nomargin">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="heading-block center">
                  <h2>{{ affiliate.fullname }}</h2>
                  <div class="center mt-2" style="display: inline-block">
                    <img
                      src="https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1664437758/web/kenya.png"
                      :alt="KE"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container mb-5">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4">
                    <img
                      src="https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto/v1492603822/commonshare/mchangawide.png"
                    />
                  </div>
                  <div class="col-md-4 justify-content-center">
                    <p class="mb-1">Location: {{ affiliate.referralcode }}</p>
                    <p class="mb-1">
                      Affiliate Code: {{ affiliate.referralcode }}
                    </p>
                    <p class="mb-1">
                      Affiliate Earning:
                      {{ amountIncentive.toLocaleString("en") }}
                    </p>
                    <p class="mb-1" style="">
                      Affiliate Referrals:
                      {{ campaignList.length.toLocaleString("en") }}
                      Fundraiser(s)
                    </p>
                    <p class="mb-1">
                      Referral URL: {{ affiliate.affiliateurl }}
                    </p>
                    <p class="mb-1">Phone: {{ affiliate.phone }}</p>
                  </div>
                  <div class="col-md-4 pStats">
                    <p class="mb-1">
                      KES {{ amountRaised.toLocaleString("en") }} Raised
                    </p>
                    <p class="mb-1">
                      {{ campaignList.length.toLocaleString("en") }} Campaigns
                    </p>
                    <p class="mb-1">
                      {{ allSupporters.toLocaleString("en") }} Supporters
                    </p>
                    <p class="mb-1">4 SDGs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SDGImpact :fund_list="campaigns" />

          <div class="container mb-5">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <h3>Data</h3>
                <div v-for="data in sdgData" :key="data">
                  <div v-for="a_data in data" :key="a_data">
                    <div v-for="(final_data, a_index) in a_data" :key="a_index">
                      <p v-if="final_data > 0">{{ a_index }}: {{ final_data.toLocaleString("en") }}</p>
                      <p v-else>{{ a_index }}: {{ final_data }}</p>
                    </div>
                    <hr>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FooterV />
  </div>
</template>
  
<script>
import { Head } from "@vueuse/head";
import { lazyLoadComponent } from "../utils/lazy-load-components";
import loader from "../components/loader.vue";

export default {
  setup() {
    return {};
  },
  components: {
    Head,
    SDGImpact: lazyLoadComponent({
      componentLoader: () => import("../components/SDGImpact.vue"),
      loadingComponent: loader,
    }),
    FooterV: lazyLoadComponent({
      componentLoader: () => import("../components/Footer/index.vue"),
      loadingComponent: loader,
    }),
  },
  data() {
    return {
      affiliate: "",
      campaigns: "",
      myList: [],
      amountIncentive: 0,
      amountRaised: 0,
      campaignList: [],
      allSupporters: 0,
      sdgData: [],
    };
  },

  mounted() {
    this.getAffiliate();
    this.getAffiliateSDG();
  },

  methods: {
    async getAffiliate() {
      fetch(process.env.VUE_APP_ROOT_API + `/service/affiliate/tracker`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ referralcode: this.$route.params.refID }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            this.affiliate = data.affiliate;
            this.amountIncentive = parseInt(data.affiliate.amount);
            this.campaigns = data.affiliate.campaigns;
            this.campaignList = data.affiliate.campaigns.split(",");
            this.getAffiliateCampaigns(data.affiliate.campaigns);
          } else {
            this.affErr = 0;
            if (data.message) {
              this.affiliateError = data.message;
            } else {
              this.affiliateError = "Error!! Unknown affiliate.";
            }
          }
        });
    },
    async getAffiliateCampaigns() {
      fetch(process.env.VUE_APP_ROOT_API + `/service/affiliate/tracker`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          referralcode: this.$route.params.refID,
          campaigns: this.campaigns,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            this.myList = data.fundraisers;
            if (data.fundraisers) {
              let mFund = data.fundraisers;
              let donors = 0;
              let amt = 0;
              mFund.forEach(function (fundraiser) {
                donors += fundraiser.supporters;
                amt += fundraiser.amount;
              });
              this.allSupporters = donors;
              this.amountRaised = amt;
            }
          } else {
            this.affErr = 0;
            if (data.message) {
              this.affiliateError = data.message;
            } else {
              this.affiliateError = "Error!! No campaigns.";
            }
          }
        });
    },

    async getAffiliateSDG() {
      fetch(process.env.VUE_APP_ROOT_API + `/service/affiliate/sdg-tracker`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          referralcode: this.$route.params.refID,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            this.sdgData = data.sdgData;
            if (data.fundraisers) {
              let mFund = data.fundraisers;
              let donors = 0;
              let amt = 0;
              mFund.forEach(function (fundraiser) {
                donors += fundraiser.supporters;
                amt += fundraiser.amount;
              });
              this.allSupporters = donors;
              this.amountRaised = amt;
            }
          } else {
            this.affErr = 0;
            if (data.message) {
              this.affiliateError = data.message;
            } else {
              this.affiliateError = "Error!! No campaigns.";
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.pStats p {
  background: #aaaaaa;
  padding: 5px;
  border-radius: 15px;
  text-align: center;
  color: #000;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.5px;
}
</style>